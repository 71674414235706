import React, { useState, useReducer, useEffect } from "react";
import { useStoreDispatch, APP_STORE_ACTION, useStoreState } from '../../../../common/storeContext';
import { Row, Col, Form, Nav, Container, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Title from '../../../pageTitle/default.js';
import { useForm } from "react-hook-form";
import { FaSearch } from "react-icons/fa";
import { TENANT_BUCKET } from '../../../../common/constants';
import { BiArrowBack } from "react-icons/bi";
import { useMiddletier } from "../../../../common/middletier";
import Loading from '../../../../components/Loading';
import { useNavigate, Link } from "react-router-dom";

const getDataFriendList = [
    { username: "b433sss", phone: 121313, status: 'active' },
    { username: "Assss", phone: 121313, status: 'active' },
    { username: "Abbb", phone: 121313, status: 'active' },
    { username: "B2sss", phone: 121313, status: 'active' },
    { username: "123123", phone: 121313, status: 'active' },
    { username: "HIHI", phone: 454545, status: 'active' },
    { username: "CHANCHAN1122", phone: 787878, status: 'active' }
];

const MY_FRIEND_ACTION = {
    SET_MY_FRIEND_SELECTION: 'SET_MY_FRIEND_SELECTION',
    SET_SELECTED_MY_FRIEND_SELECTION: 'SET_SELECTED_MY_FRIEND_SELECTION',
    SET_FRIEND_LIST: 'SET_FRIEND_LIST',
    SET_SELECTED_FRIEND: 'SET_SELECTED_FRIEND',
    SET_SEARCH_INPUT: 'SET_SEARCH_INPUT',
    SET_SEARCH_FRIEND: 'SET_SEARCH_FRIEND',
    SET_FRIEND_REQUEST_SELECTION: 'SET_MY_FRIEND_SELECTION',
    SET_SELECTED_FRIEND_REQUEST_SELECTION: 'SET_SELECTED_FRIEND_REQUEST_SELECTION',
    SET_FRIEND_REQUEST: 'SET_FRIEND_REQUEST',
    SET_SENT_FRIEND_REQUEST: 'SET_SENT_FRIEND_REQUEST',
    SET_SELECTED_MY_FRIEND_ACTION: 'SET_SELECTED_MY_FRIEND_ACTION',
    SET_MY_FRIEND_ACTION_REFRESH: 'SET_MY_FRIEND_ACTION_REFRESH',
    SET_FRIEND_REQUEST_CONTENT: 'SET_FRIEND_REQUEST_CONTENT'
}

const FRIEND_TRANSFER_ACTION = {
    SET_SHOW_CREATE_PASSWORD_DIALOG: 'SET_SHOW_CREATE_PASSWORD_DIALOG',
    SET_SHOW_FRIEND_TRANSFER_DIALOG: 'SET_SHOW_FRIEND_TRANSFER_DIALOG',
    SET_SHOW_FRIEND_TRANSFER_PASSWORD_DIALOG: 'SET_SHOW_FRIEND_TRANSFER_PASSWORD_DIALOG',
    SET_TRANSFER_AMOUNT: 'SET_TRANSFER_AMOUNT'
}

const initialMyFriendData = {
    myFriendSelection: ['FRIEND_LIST', 'FRIEND_REQUEST_LIST'],
    selectedMyFriendSelection: 'FRIEND_LIST',
    friendList: [],
    selectedFriend: {},
    searchInput: '',
    searchFriend: {},
    friendRequestSelection: ['FRIEND_REQUEST', 'SENT_FRIEND_REQUEST'],
    selectedFriendRequestSelection: 'FRIEND_REQUEST',
    friendRequest: [],
    sentFriendRequest: [],
    friendRequestContent: [],
    myFriendAction: ['FRIEND_LIST', 'SEARCH_FRIEND', 'FRIEND_REQUEST_LIST'],
    myFriendActionRefresh: false,
    selectedMyFriendAction: 'FRIEND_LIST'

}

const initialFriendTransferData = {
    showCreatePasswordDialog: false,
    showFriendTransferDialog: false,
    showFriendTransferPasswordDialog: false,
    transferAmount: 0
}


const myFriendReducer = (state, action) => {
    switch (action.type) {

        case MY_FRIEND_ACTION.SET_MY_FRIEND_SELECTION: {
            const myFriendSelection = action.payload
            console.log('myFriendSelection?')
            return { ...state, myFriendSelection, selectedMyFriendSelection: myFriendSelection.length > 0 ? myFriendSelection[0] : '' };

        }

        case MY_FRIEND_ACTION.SET_SELECTED_MY_FRIEND_SELECTION: {
            const selectedMyFriendSelection = action.payload
            console.log('selectedMyFriendSelection?')
            return { ...state, selectedMyFriendSelection };
        }

        case MY_FRIEND_ACTION.SET_FRIEND_LIST: {
            const friendList = action.payload
            console.log('friendList?')
            return { ...state, friendList };
        }

        case MY_FRIEND_ACTION.SET_SELECTED_FRIEND: {
            const selectedFriend = action.payload
            console.log('selectedFriend')

            return { ...state, selectedFriend };
        }

        case MY_FRIEND_ACTION.SET_SEARCH_INPUT: {
            const searchInput = action.payload
            return { ...state, searchInput };
        }

        case MY_FRIEND_ACTION.SET_SEARCH_FRIEND: {
            const searchFriend = action.payload
            return { ...state, searchFriend };
        }

        case MY_FRIEND_ACTION.SET_FRIEND_REQUEST_SELECTION: {
            const friendRequestSelection = action.payload
            return { ...state, friendRequestSelection };
        }

        case MY_FRIEND_ACTION.SET_SELECTED_FRIEND_REQUEST_SELECTION: {
            const selectedFriendRequestSelection = action.payload
            return { ...state, selectedFriendRequestSelection };
        }

        case MY_FRIEND_ACTION.SET_FRIEND_REQUEST: {
            const friendRequest = action.payload
            return { ...state, friendRequest };
        }

        case MY_FRIEND_ACTION.SET_SENT_FRIEND_REQUEST: {
            const sentFriendRequest = action.payload
            return { ...state, sentFriendRequest };
        }

        case MY_FRIEND_ACTION.SET_FRIEND_REQUEST_CONTENT: {
            const friendRequestContent = action.payload
            return { ...state, friendRequestContent };
        }

        case MY_FRIEND_ACTION.SET_SELECTED_MY_FRIEND_ACTION: {
            const selectedMyFriendAction = action.payload
            return { ...state, selectedMyFriendAction };
        }

        case MY_FRIEND_ACTION.SET_MY_FRIEND_ACTION_REFRESH: {
            const myFriendActionRefresh = action.payload
            return { ...state, myFriendActionRefresh };
        }


        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

const friendTransferReducer = (state, action) => {
    switch (action.type) {

        case FRIEND_TRANSFER_ACTION.SET_SHOW_CREATE_PASSWORD_DIALOG: {
            const showCreatePasswordDialog = action.payload
            return { ...state, showCreatePasswordDialog };
        }

        case FRIEND_TRANSFER_ACTION.SET_SHOW_FRIEND_TRANSFER_DIALOG: {
            const showFriendTransferDialog = action.payload
            return { ...state, showFriendTransferDialog };
        }

        case FRIEND_TRANSFER_ACTION.SET_SHOW_FRIEND_TRANSFER_PASSWORD_DIALOG: {
            const showFriendTransferPasswordDialog = action.payload
            return { ...state, showFriendTransferPasswordDialog };
        }

        case FRIEND_TRANSFER_ACTION.SET_TRANSFER_AMOUNT: {
            const transferAmount = action.payload
            return { ...state, transferAmount };
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

// VALIDATION RULES
const getValidationRules = (type, t, getCreatePasswordValue) => {
    switch (type) {

        case 'password':
            return {
                required: t('error_password_empty'),
                minLength: { value: 6, message: t('error_password_length') },
            };

        case 'transferAmount':
            return {
                required: false,
            };

        case 'remark':
            return {
                required: false,
            };
        case 'confirm_password':
            return {
                required: t('error_confirm_password_empty'),
                minLength: { value: 6, message: t('error_confirm_password_length') },
                validate: (value) => {
                    const { password } = getCreatePasswordValue();
                    return (
                        password === value || t('error_confirm_password_matchPassword')
                    );
                },
            };

        default:
            return {};
    }
};

const MyFriendBody = () => {
    const { query, queries, mutation } = useMiddletier();
    const { t } = useTranslation();
    const [myFriendState, myFriendDispatch] = useReducer(myFriendReducer, initialMyFriendData)
    const [friendTransferState, friendTransferDispatch] = useReducer(friendTransferReducer, initialFriendTransferData)
    const [isLoading, setLoading] = useState(false);
    const [disabledTransferSubmit, setDisabledTransferSubmit] = useState(true)
    const appDispatch = useStoreDispatch()
    const appState = useStoreState()
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })

    const {
        register: createPassword,
        getValues: getCreatePasswordValue,
        formState: { errors: errorsCreatePassword, isValid: isValidCreatePassword },
        handleSubmit: handleSubmitCreatePassword,
        reset: resetCreatePassword,
    } = useForm({
        mode: "onChange",
    });

    const {
        register: friendTransfer,
        getValues: getFriendTransferValue,
        formState: { errors: errorsFriendTransfer, isValid: isValidFriendTransfer },
        handleSubmit: handleSubmitFriendTransfer,
        reset: resetFriendTransfer,
    } = useForm({
        mode: "onChange",
    });

    const {
        register: friendTransferPassword,
        getValues: getFriendTransferPasswordValue,
        formState: { errors: errorsFriendTransferPassword, isValid: isValidFriendTransferPassword },
        handleSubmit: handleSubmitFriendTransferPassword,
        reset: resetFriendTransferPassword,
    } = useForm({
        mode: "onChange",
    });


    // CHANGE SEARCH INPUT
    const onChangeSearchInput = (newValue) => {
        myFriendDispatch({ type: MY_FRIEND_ACTION.SET_SEARCH_INPUT, payload: newValue })
    }

    const onChangeFriendSelection = (newValue) => {
        myFriendDispatch({ type: MY_FRIEND_ACTION.SET_SELECTED_MY_FRIEND_SELECTION, payload: newValue })
        myFriendDispatch({ type: MY_FRIEND_ACTION.SET_SELECTED_MY_FRIEND_ACTION, payload: newValue })

    }

    const onChangeFriendRequestSelection = (newValue) => {
        myFriendDispatch({ type: MY_FRIEND_ACTION.SET_FRIEND_REQUEST_CONTENT, payload: [] })
        myFriendDispatch({ type: MY_FRIEND_ACTION.SET_SELECTED_FRIEND_REQUEST_SELECTION, payload: newValue })
    }

    const onClickFriendDetail = (newValue) => {
        if (!isDesktopOrLaptop) {
            myFriendDispatch({ type: MY_FRIEND_ACTION.SET_SELECTED_FRIEND, payload: newValue })
            myFriendDispatch({ type: MY_FRIEND_ACTION.SET_SELECTED_MY_FRIEND_ACTION, payload: 'FRIEND_DETAIL' })
        } else {
            myFriendDispatch({ type: MY_FRIEND_ACTION.SET_SELECTED_FRIEND, payload: newValue })
            // event.preventDefault();
        }
    }

    const onHideCreatePasswordDialog = () => {
        friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_SHOW_CREATE_PASSWORD_DIALOG, payload: false })
    }

    const onHideFriendTransferDialog = () => {
        friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_SHOW_FRIEND_TRANSFER_DIALOG, payload: false })
    }

    const onHideFriendTransferPasswordDialog = () => {
        friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_SHOW_FRIEND_TRANSFER_PASSWORD_DIALOG, payload: false })
    }

    const onChangeTansferAmount = (newValue) => {
        friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_TRANSFER_AMOUNT, payload: newValue })
    }

    const navigateTo = (event) => {
        if (!isDesktopOrLaptop) {
            if (myFriendState.selectedMyFriendAction === 'SEARCH_FRIEND') {
                myFriendDispatch({ type: MY_FRIEND_ACTION.SET_SELECTED_MY_FRIEND_ACTION, payload: 'FRIEND_LIST' })
            }
            else if (myFriendState.selectedMyFriendAction === 'FRIEND_REQUEST_LIST') {
                myFriendDispatch({ type: MY_FRIEND_ACTION.SET_SELECTED_MY_FRIEND_ACTION, payload: 'FRIEND_LIST' })
            }
            else if (myFriendState.selectedMyFriendAction === 'FRIEND_DETAIL') {
                myFriendDispatch({ type: MY_FRIEND_ACTION.SET_SELECTED_MY_FRIEND_ACTION, payload: 'FRIEND_LIST' })
            }

        } else {
            event.preventDefault();
        }
    }

    //  --------------- TRANSFER FRIEND START --------------- 
    const onClickTransferFriend = () => {
        setLoading(true)

        const method = 'member'
        query({
            method,
            params: [
                { code: 'username', graphqlType: 'String', required: true, value: appState?.user?.username },
            ],
            attributes: ['friend_transfer_password']

        }).then(({ data }) => {
            setLoading(false)
            if (data[method]?.friend_transfer_password !== null) {
                friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_SHOW_FRIEND_TRANSFER_DIALOG, payload: true })

            } else {
                friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_SHOW_CREATE_PASSWORD_DIALOG, payload: true })
            }

        }).catch((error) => {
            setLoading(false)
            if (error?.networkError?.statusCode === 401) {
                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
                appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: error.message.toString(), typeAlert: 'error' }
                });
            }
        })

    }

    const onSubmitCreatePassword = () => {
        const { confirm_password } = getCreatePasswordValue()
        setLoading(true)
        const method = 'createFriendTransferPasswordByMember'

        mutation([{
            method,
            params: [
                { code: 'member', graphqlType: 'String', required: false, value: appState?.user?.username },
                { code: 'password', graphqlType: 'String', required: true, value: confirm_password },
            ],
            attributes: []

        }])
            .then(({ data }) => {
                setLoading(false)
                friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_SHOW_CREATE_PASSWORD_DIALOG, payload: false })
                friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_SHOW_FRIEND_TRANSFER_DIALOG, payload: true })
                // appDispatch({
                //     type: APP_STORE_ACTION.SHOW_ALERT,
                //     payload: {
                //         description: 'Create Friend Transfer Password Successful', typeAlert: 'success', onHide: () => {
                //             friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_SHOW_CREATE_PASSWORD_DIALOG, payload: false })
                //             friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_SHOW_FRIEND_TRANSFER_DIALOG, payload: true })
                //         }
                //     }
                // });

            })
            .catch((error) => {
                setLoading(false)
                console.log('error: ', error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }
            })
        resetCreatePassword()
    }

    const onSubmitFriendTransfer = () => {
        friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_SHOW_FRIEND_TRANSFER_DIALOG, payload: false })
        friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_SHOW_FRIEND_TRANSFER_PASSWORD_DIALOG, payload: true })
        resetFriendTransfer()
    }

    const onSubmitFriendTransferPassword = (data) => {
        const { remark } = getFriendTransferValue();
        setLoading(true)
        const method = 'transferFriendByMember'

        mutation([{
            method,
            params: [
                { code: 'member', graphqlType: 'String', required: false, value: appState?.user?.username },
                { code: 'friend', graphqlType: 'String', required: true, value: myFriendState.selectedFriend?.friend },
                { code: 'password', graphqlType: 'String', required: true, value: data.password },
                { code: 'amount', graphqlType: 'Float', required: true, value: parseFloat(friendTransferState.transferAmount) },
                { code: 'remark', graphqlType: 'String', required: false, value: remark },
            ],
            attributes: []

        }])
            .then(({ data }) => {
                setLoading(false)
                friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_TRANSFER_AMOUNT, payload: 0 })
                friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_SHOW_FRIEND_TRANSFER_PASSWORD_DIALOG, payload: false })
                appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: 'Friend Transfer Successful', typeAlert: 'success' }
                });
            })
            .catch((error) => {
                friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_TRANSFER_AMOUNT, payload: 0 })
                friendTransferDispatch({ type: FRIEND_TRANSFER_ACTION.SET_SHOW_FRIEND_TRANSFER_PASSWORD_DIALOG, payload: false })
                setLoading(false)
                console.log('error: ', error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }
            })
        resetFriendTransferPassword()
    }

    useEffect(() => {

        if ((parseFloat(friendTransferState.transferAmount) > 0)) {
            setDisabledTransferSubmit(false)
        }
        else {
            setDisabledTransferSubmit(true)
        }

    }, [friendTransferState.transferAmount])



    // --------------- TRANSFER FRIEND END --------------- 

    // --------------- SEARCH FRIEND START ---------------
    const onClickSearchFriend = () => {
        setLoading(true)

        // Users cannot search for themselves
        if (myFriendState.searchInput?.trim() !== appState.user?.username) {
            query({
                method: 'member',
                params: [
                    { code: 'username', graphqlType: 'String', required: true, value: myFriendState.searchInput },
                ],
                attributes: ['username', 'phone_number']
            }).then(({ data }) => {
                setLoading(false)
                if (data['member'] !== null) {
                    myFriendDispatch({ type: MY_FRIEND_ACTION.SET_SEARCH_FRIEND, payload: data['member'] })

                } else {
                    myFriendDispatch({ type: MY_FRIEND_ACTION.SET_SEARCH_FRIEND, payload: {} })
                }

                myFriendDispatch({ type: MY_FRIEND_ACTION.SET_SELECTED_MY_FRIEND_ACTION, payload: 'SEARCH_FRIEND' })

            }).catch((error) => {
                setLoading(false)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }
            })
        } else {
            setLoading(false)
            appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: 'You cannot search for yourself.', typeAlert: 'error' }
            });
        }


    }

    const onClickSendFriendRequest = () => {
        setLoading(true)
        mutation([{
            method: 'sendMemberFriendRequest',
            params: [
                { code: 'member', graphqlType: 'String', required: false, value: appState?.user?.username },
                { code: 'friend', graphqlType: 'String', required: true, value: myFriendState.searchFriend?.username },
            ],
            attributes: []
        }]).then(({ data }) => {
            setLoading(false)

            appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: `The friend request has been sent, please wait for a response.`, typeAlert: 'success' }
            });


        }).catch((error) => {
            setLoading(false)
            if (error?.networkError?.statusCode === 401) {
                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
                appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: error.message.toString(), typeAlert: 'error' }
                });
            }
        })
        // myFriendDispatch({ type: MY_FRIEND_ACTION.SET_SEARCH_INPUT, payload: newValue })
    }

    const SearchFriend = () => {

        return (
            <>
                <div className="searchFriend_wrap">
                    {Object.keys(myFriendState.searchFriend).length !== 0 ?
                        <div className="searchFriend_container">
                            <div className="searchFriend_avatar">
                                <img src={`${TENANT_BUCKET}/icon/wallet_user.png`} alt="friend avatar" />
                            </div>
                            <div className="searchFriend_info">
                                {myFriendState.searchFriend?.username}
                            </div>
                            <div className="searchFriend_action">
                                <button className="font_button color_button wallet_btnSubmit btnAddFriend" type="button" onClick={onClickSendFriendRequest}>
                                    {t('add_friend')}
                                </button>
                            </div>
                        </div>
                        : <div className="history_noRecord_wrap">
                            <img src={`${TENANT_BUCKET}/icon/noRecord.png`} alt="no record icon" />
                            <label>{t('no_record')}</label>
                        </div>
                    }
                </div>
            </>
        )
    }

    // --------------- SEARCH FRIEND END ---------------

    // --------------- FRIEND REQUEST START ---------------

    useEffect(() => {
        console.log('or zhebian?')
        if (myFriendState.selectedMyFriendAction === 'FRIEND_REQUEST_LIST') {
            setLoading(true)
            const method = myFriendState.selectedFriendRequestSelection === 'FRIEND_REQUEST' ? 'getMemberFriendRequest' : 'getMemberFriendSentRequest'
            query({
                method,
                params: [
                    { code: 'member', graphqlType: 'String', required: false, value: appState?.user?.username },
                ],
                attributes: []

            }).then(({ data }) => {
                setLoading(false)
                myFriendDispatch({ type: MY_FRIEND_ACTION.SET_FRIEND_REQUEST_CONTENT, payload: data[method] })

            }).catch((error) => {
                setLoading(false)
                // console.log('error: ', error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }
            })

        }

    }, [query, myFriendState.selectedMyFriendAction, myFriendState.selectedFriendRequestSelection, appState?.user?.username, appDispatch, myFriendState.myFriendActionRefresh]);

    const onClickFriendRequestAction = (action, friend) => {
        setLoading(true)
        let usernameFriend = myFriendState.selectedFriendRequestSelection === 'SENT_FRIEND_REQUEST' ? (friend?.friend) : (friend?.member)
        let method = ''
        if (action === 'CANCEL') {
            method = 'cancelMemberFriendRequest'
        } else if (action === 'APPROVE') {
            method = 'approveMemberFriendRequest'
        } else {
            method = 'rejectMemberFriendRequest'
        }

        mutation([{
            method,
            params: [
                { code: 'member', graphqlType: 'String', required: false, value: appState?.user?.username },
                { code: 'friend', graphqlType: 'String', required: true, value: usernameFriend },
            ],
            attributes: []
        }]).then(({ data }) => {
            setLoading(false)

            myFriendDispatch({ type: MY_FRIEND_ACTION.SET_MY_FRIEND_ACTION_REFRESH, payload: !myFriendState.myFriendActionRefresh })

            appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: `${action} SUCCESSFUL`, typeAlert: 'success' }
            });



        }).catch((error) => {
            setLoading(false)
            if (error?.networkError?.statusCode === 401) {
                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
                appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: error.message.toString(), typeAlert: 'error' }
                });
            }
        })

    }

    const FriendRequest = () => {
        return (
            <>
                <div className="friendRequest_wrap">
                    <Nav variant="pills" className={`friendRequest_tab filterTab tab_pills_color font_tab_pills`} onSelect={(key) => { onChangeFriendRequestSelection(key) }}>

                        {myFriendState.friendRequestSelection?.map(function (item, index) {
                            return (
                                <Nav.Item key={item} >
                                    <Nav.Link eventKey={item} className={`friendRequestTab_selection ${item === myFriendState.selectedFriendRequestSelection ? 'color_active active' : ''}`}>
                                        {t(item?.toLowerCase())}
                                    </Nav.Link>
                                </Nav.Item>
                            )
                        })}
                    </Nav>
                    {
                        myFriendState.friendRequestContent?.length ? (
                            myFriendState.friendRequestContent.map(function (item, index) {
                                return (
                                    <div className="searchFriend_container" key={item?.id}>
                                        <div className="searchFriend_avatar">
                                            <img src={`${TENANT_BUCKET}/icon/wallet_user.png`} alt="friend avatar" />
                                        </div>
                                        <div className="searchFriend_info">
                                            {myFriendState.selectedFriendRequestSelection === 'SENT_FRIEND_REQUEST' ? (item?.friend) : (item?.member)}
                                        </div>
                                        <div className="searchFriend_action">
                                            {myFriendState.selectedFriendRequestSelection === 'SENT_FRIEND_REQUEST' ?
                                                <button className="font_button color_button wallet_btnSubmit" type="button" onClick={() => onClickFriendRequestAction('CANCEL', item)}>
                                                    {t('cancel')}
                                                </button> :
                                                <>
                                                    <button className="font_button color_button wallet_btnSubmit btnApprove" type="button" onClick={() => onClickFriendRequestAction('APPROVE', item)}>
                                                        {t('approve')}
                                                    </button>
                                                    <button className="font_button color_button wallet_btnSubmit btnReject" type="button" onClick={() => onClickFriendRequestAction('REJECT', item)}>
                                                        {t('reject')}
                                                    </button>
                                                </>
                                            }

                                        </div>
                                    </div>
                                )
                            })
                        ) : (<div className="d-flex align-items-center justify-content-center flex-column">
                            <img src={`${TENANT_BUCKET}/icon/noRecord.png`} alt={''} className="no_record_img" />
                            <span className="pt-1">{t('no_record')}</span>
                        </div>)
                    }
                </div>
            </>
        )
    }
    // --------------- FRIEND REQUEST END ---------------

    // --------------- FRIEND LIST START --------------- 
    useEffect(() => {

        if (myFriendState.selectedMyFriendAction === 'FRIEND_LIST') {
            setLoading(true)
            const method = 'getMemberFriendList'
            query({
                method,
                params: [
                    { code: 'member', graphqlType: 'String', required: false, value: appState?.user?.username },
                ],
                attributes: []

            }).then(({ data }) => {
                setLoading(false)

                let getFriendList = data['getMemberFriendList']
                console.log('getFriendList', getFriendList)
                if (Object.isFrozen(data['getMemberFriendList'])) {
                    console.log('getMemberFriendList frozen')
                } else {
                    console.log('getMemberFriendList no frozen')
                }

                let sortedData = [];
                try {
                    // sortedData = getFriendList?.sort((a, b) => (a.friend || '').localeCompare(b.friend || ''));
                    if (Object.isFrozen(getFriendList)) {
                        
                        sortedData = [...getFriendList].sort((a, b) => (a.friend || '').localeCompare(b.friend || ''));
                    } else {
                        
                        sortedData = getFriendList.sort((a, b) => (a.friend || '').localeCompare(b.friend || ''));
                    }
                } catch (error) {
                    console.error('Sorting error:', error);
                }
                console.log('sortedData hihi221', sortedData)
                const groupForNumber = [];
                const groupedData = sortedData.reduce((acc, currentValue) => {
                    console.log('currentValue.friend[0]', currentValue.friend[0])
                    let firstLetter = currentValue.friend[0].toUpperCase();
                    if (!firstLetter.match(/[A-Z]/)) {
                        groupForNumber.push(currentValue);
                    } else {
                        if (!acc[firstLetter]) {
                            acc[firstLetter] = [];
                        }
                        acc[firstLetter].push(currentValue);
                    }
                    return acc;
                }, {});

                if (groupForNumber.length > 0) {
                    groupedData["#"] = groupForNumber;
                }

                myFriendDispatch({ type: MY_FRIEND_ACTION.SET_FRIEND_LIST, payload: groupedData })

            }).catch((error) => {
                setLoading(false)
                console.log('error: ', error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }
            })

        }

    }, [query, myFriendState.selectedMyFriendAction, appState?.user?.username, appDispatch]);

    const FriendList = () => {

        return (
            <div className="friendList_wrap">
                {Object.keys(myFriendState.friendList).map((group) => (
                    <div key={group} className="friendList_group">
                        <div className="friendList_group_title">{group}</div>
                        <div className="friendList_group_item_container">
                            {myFriendState.friendList[group].map((item) => (
                                <div key={item.friend} className="friendList_group_item" onClick={() => onClickFriendDetail(item)}>
                                    <div className="friendList_avatar">
                                        <img src={`${TENANT_BUCKET}/icon/${item?.gender === 'FEMALE' ? 'female_avatar' : 'male_avatar'}.png`} alt="friend avatar" />
                                    </div>
                                    <div className="friendList_info">
                                        <div className="friendList_info_username">{item.friend}</div>
                                        {isDesktopOrLaptop && (<> <div className="friendList_info_phone">{item.phone_number}</div>
                                            <div className="friendList_info_status">{item.status}</div></>)}
                                    </div>
                                    <div className="friendList_action">
                                        {isDesktopOrLaptop && <button className="font_button color_button wallet_btnSubmit btnTransfer" type="button" onClick={onClickTransferFriend}>
                                            {t('transfer')}
                                        </button>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        );
    };


    // --------------- FRIEND LIST END --------------- 

    // --------------- FRIEND DETAIL START --------------- 
    const FriendDetail = () => {

        return (
            <div className="friendDetail_wrap">
                <div className="friendDetail_group_item">
                    <div className="friendDetail_avatar">
                        <img src={`${TENANT_BUCKET}/icon/${myFriendState.selectedFriend?.gender === 'FEMALE' ? 'female_avatar' : 'male_avatar'}.png`} alt="friend avatar" />
                    </div>
                    <div className="friendDetail_info">
                        <div className="friendDetail_info_username">{myFriendState.selectedFriend?.friend}</div>
                        <div className="friendDetail_info_phone">{myFriendState.selectedFriend?.phone_number}</div>
                        <div className="friendDetail_info_status">{myFriendState.selectedFriend?.status}</div>
                    </div>
                </div>
                <div className="friendDetail_action">
                    <button className="font_button color_button wallet_btnSubmit btnTransfer" type="button" onClick={onClickTransferFriend}>
                        {t('transfer')}
                    </button>
                </div>
            </div>
        );
    };
    // --------------- FRIEND DETAIL END --------------- 


    return (
        <>
            <div className="myFriend_body">
                {isDesktopOrLaptop ?
                    <div className="font_h2 color_primary wallet_container_title">{t('my_friend')}</div> :
                    (myFriendState.selectedMyFriendAction === 'FRIEND_LIST' && (<Title title={"my_friend"} desktopToggle={false} mobileToggle={true} id={'my_friend_title'} />))
                }

                {(!isDesktopOrLaptop && myFriendState.selectedMyFriendAction !== 'FRIEND_LIST') && (<section className={`section pageTitle font_h2 color_primary mobile d-lg-none`} id={``}>
                    <>
                        <Container className={` title_container`}>
                            <Link onClick={(event) => navigateTo(event)}><BiArrowBack /></Link>
                            <div>  {myFriendState.selectedMyFriendAction === 'FRIEND_REQUEST_LIST' ?
                                t('friend_request') : (
                                    myFriendState.selectedMyFriendAction === 'SEARCH_FRIEND' ?
                                        t('new_friend') : (
                                            myFriendState.selectedMyFriendAction === 'FRIEND_DETAIL' ?
                                                t('detail') : t('my_friend')
                                        )
                                )}
                            </div>
                        </Container>
                    </>
                </section>)}


                <div className="wallet_container_wrap">
                    {((!isDesktopOrLaptop && myFriendState.selectedMyFriendAction !== 'FRIEND_DETAIL') || isDesktopOrLaptop) && (
                        <section className="myFriend_header_section">
                            <div className="myFriendSearchBar">
                                <Form.Control
                                    type="text"
                                    name="Search"
                                    placeholder={t('search')}
                                    className="input_search"
                                    aria-label="Search"
                                    onChange={(e) => onChangeSearchInput(e.target.value)}
                                    value={myFriendState.searchInput ?? ''}
                                />
                                <button className="font_button color_button wallet_btnSubmit btnSearch" type="button" onClick={onClickSearchFriend}>
                                    <FaSearch />
                                </button>
                            </div>
                            {((!isDesktopOrLaptop && myFriendState.selectedMyFriendAction !== 'FRIEND_REQUEST_LIST') || isDesktopOrLaptop) && (<div className="myFriendSelectionWrap">
                                <div className="myFriendSelection" onClick={() => onChangeFriendSelection('FRIEND_LIST')}>
                                    <img src={`${TENANT_BUCKET}/icon/myFriend_friendList.png`} alt="friend_list" />
                                    <div>  {t('friend_list')}</div>
                                </div>
                                <div className="myFriendSelection" onClick={() => onChangeFriendSelection('FRIEND_REQUEST_LIST')}>
                                    <img src={`${TENANT_BUCKET}/icon/myFriend_friendRequest.png`} alt="friend_request_list" />
                                    <div>{t('friend_request')}</div>
                                </div>

                            </div>)}
                        </section>
                    )}
                    <section className="myFriend_main_section">
                        {myFriendState.selectedMyFriendAction === 'FRIEND_LIST' ?
                            <FriendList /> :
                            (myFriendState.selectedMyFriendAction === 'SEARCH_FRIEND' ?
                                <SearchFriend /> :
                                (myFriendState.selectedMyFriendAction === 'FRIEND_REQUEST_LIST' ?
                                    <FriendRequest /> :
                                    (myFriendState.selectedMyFriendAction === 'FRIEND_DETAIL') ? <FriendDetail /> : <FriendList />)
                            )
                        }
                    </section>
                </div>

            </div>
            {isLoading && <Loading />}
            <Modal show={friendTransferState.showCreatePasswordDialog} onHide={onHideCreatePasswordDialog} centered id="createPasswordDialog" backdrop="static" backdropClassName="createPasswordDialogBackdrop">
                <Modal.Header closeButton>
                    <Modal.Title>{t('create_friend_transfer_password')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="createPassword_Form" autoComplete="off" onSubmit={handleSubmitCreatePassword(onSubmitCreatePassword)}>

                        {/* PASSWORD */}
                        <Form.Group className="form_field" >
                            <Form.Label>{t('password')}<div className="form_required">*</div></Form.Label>
                            <div className="form_field_input">
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder={t('password')}
                                    className="input_password"
                                    aria-label="Password"
                                    autoComplete="off"
                                    onKeyDown={(event) => {
                                        if (event.key === ' ') {
                                            event.preventDefault();
                                        }
                                    }}
                                    onInput={(event) => {
                                        if (event.target.value.includes(' ')) {
                                            event.target.value = event.target.value.replace(/ /g, '');
                                        }
                                    }}
                                    {...createPassword("password", getValidationRules('password', t))}
                                />
                                <p className="register_error_msg">{errorsCreatePassword.password?.message}</p>
                            </div>
                        </Form.Group>

                        {/* CONFIRM PASSWORD */}
                        <Form.Group className="form_field" >
                            <Form.Label>{t('confirm_password')}<div className="form_required">*</div></Form.Label>
                            <div className="form_field_input">
                                <Form.Control
                                    type="password"
                                    name="confirm_password"
                                    placeholder={t('confirm_password')}
                                    className="input_confirm_password"
                                    aria-label="Confirm Password"
                                    autoComplete="off"
                                    onKeyDown={(event) => {
                                        if (event.key === ' ') {
                                            event.preventDefault();
                                        }
                                    }}
                                    onInput={(event) => {
                                        if (event.target.value.includes(' ')) {
                                            event.target.value = event.target.value.replace(/ /g, '');
                                        }
                                    }}
                                    {...createPassword("confirm_password", getValidationRules('confirm_password', t, getCreatePasswordValue))}
                                />
                                <p className="register_error_msg">{errorsCreatePassword.confirm_password?.message}</p>
                            </div>
                        </Form.Group>

                        {/* SUBMIT */}
                        <Form.Group className="form_field" >
                            <div className="form_field_input input_submit_wrap">
                                {/* <button type='button' className="font_button color_button wallet_btnSubmit" onClick={onSubmitCreatePassword}>{t('submit')}</button> */}
                                <input className="font_button color_button wallet_btnSubmit" type='submit' value={t('submit')} />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={friendTransferState.showFriendTransferDialog} onHide={onHideFriendTransferDialog} centered id="friendTransferDialog" backdrop="static" backdropClassName="friendTransferDialogBackdrop">
                <Modal.Header closeButton>
                    <Modal.Title>{t('friend_transfer')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="friendTransfer_Form" onSubmit={handleSubmitFriendTransfer(onSubmitFriendTransfer)} autoComplete="off">

                        {/* TRANSFER AMOUNT */}
                        <Form.Group className="form_field" >
                            <Form.Label>{t('transfer_amount')}<div className="form_required">*</div></Form.Label>
                            <div className="form_field_input">
                                <Form.Control
                                    type="text"
                                    // step={1}
                                    // placeholder={t('transfer_amount_placeholder')}
                                    className="input_transferAmount"
                                    min={0}
                                    value={friendTransferState.transferAmount === 0 ? '' : friendTransferState.transferAmount}
                                    onChange={(evt) => { onChangeTansferAmount(evt.target.value) }}
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    aria-label="transferAmount"

                                    onInput={(e) => {

                                        let value = appState.currencyFormat.decimal_point === 0
                                            ? e.target.value.replace(/[^0-9]/g, '')
                                            : e.target.value.replace(/[^0-9.]/g, '');

                                        if (value.split('.').length - 1 > 1) {
                                            value = value.substring(0, value.lastIndexOf('.'));
                                        }

                                        const decimal_part = value.split(".")[1];
                                        if (decimal_part && decimal_part.length > appState.currencyFormat.decimal_point) {
                                            value = value.slice(0, -1);
                                        }
                                        e.target.value = value;
                                        // e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    }}
                                // {...friendTransfer("transferAmount", getValidationRules('transferAmount', t))}
                                />
                                {/* <p className="register_error_msg">{errorsFriendTransfer.password?.message}</p> */}
                            </div>
                        </Form.Group>

                        {/* REMARK */}
                        <Form.Group className="form_field" >
                            <Form.Label>{t('remark')}</Form.Label>
                            <div className="form_field_input">
                                <Form.Control
                                    type="text"
                                    name="remark"
                                    className="input_remark"
                                    aria-label="Remark"
                                    autoComplete="off"
                                    as="textarea"
                                    {...friendTransfer("remark", getValidationRules('remark', t))}
                                />

                            </div>
                        </Form.Group>

                        {/* SUBMIT */}
                        <Form.Group className="form_field" >
                            <div className="form_field_input input_submit_wrap">
                                <input type='submit' className="font_button color_button wallet_btnSubmit" value={t('submit')} disabled={disabledTransferSubmit} />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={friendTransferState.showFriendTransferPasswordDialog} onHide={onHideFriendTransferPasswordDialog} centered id="friendTransferPasswordDialog" backdrop="static" backdropClassName="friendTransferPasswordDialogBackdrop">
                <Modal.Header closeButton>
                    <Modal.Title>{t('friend_transfer_password')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="friendTransferPassword_Form" onSubmit={handleSubmitFriendTransferPassword(onSubmitFriendTransferPassword)} autoComplete="off">

                        {/* PASSWORD */}
                        <Form.Group className="form_field" >
                            <Form.Label>{t('password')}<div className="form_required">*</div></Form.Label>
                            <div className="form_field_input">
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder={t('password')}
                                    className="input_password"
                                    aria-label="Password"
                                    autoComplete="off"
                                    onKeyDown={(event) => {
                                        if (event.key === ' ') {
                                            event.preventDefault();
                                        }
                                    }}
                                    onInput={(event) => {
                                        if (event.target.value.includes(' ')) {
                                            event.target.value = event.target.value.replace(/ /g, '');
                                        }
                                    }}
                                    {...friendTransferPassword("password", getValidationRules('password', t))}
                                />
                                <p className="register_error_msg">{errorsFriendTransferPassword.password?.message}</p>
                            </div>
                        </Form.Group>

                        {/* SUBMIT */}
                        <Form.Group className="form_field" >
                            <div className="form_field_input input_submit_wrap">
                                <input type='submit' className="font_button color_button wallet_btnSubmit" value={t('submit')} disabled={!isValidFriendTransferPassword} />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>

        </>

    );




};






export default MyFriendBody;
