import React, { useEffect, useReducer, useRef, useState } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Navigate, useNavigate } from 'react-router-dom';
import { useMiddletier } from "../../common/middletier";
import { APP_STORE_ACTION, useStoreDispatch, useStoreState } from '../../common/storeContext';
import Loading from '../../components/Loading';
import PageTitle from '../pageTitle/template_01';
import './template_01.css';
import FingerprintJS from '@fingerprintjs/fingerprintjs'


const FORGOT_USERNAME_ACTION = {
  SET_EXIST_STATUS: 'SET_EXIST_STATUS',
  SET_SELECTED_VERIFY_TYPE: 'SET_SELECTED_VERIFY_TYPE',
  SET_VERIFY_ID: 'SET_VERIFY_ID',
  SET_VERIFY_CODE: 'SET_VERIFY_CODE',
  SET_CODE_VALIDATION_RESULT: 'SET_CODE_VALIDATION_RESULT',
  SET_COUNTRY_DAILING_CODE: 'SET_COUNTRY_DAILING_CODE',
  SET_SELECTED_COUNTRY_DAILING_CODE: 'SET_SELECTED_COUNTRY_DAILING_CODE'
}

const initialForgotUsernameData = {
  selectedVerifyType: 'SMS',
  verifyID_SMS: '',
  verifyCode_SMS: '',
  verifyID_EMAIL: '',
  verifyCode_EMAIL: '',
  codeValidationResult: true,
  phoneNumberExist: true,
  emailExist: true,
  phoneNumberError: false,
  emailError: false,
  countryDailingCode: [],
  selectedCountryDailingCode: ''
  // phoneNumberExistAlert: '',
  // emailExistAlert: '',
}

const forgotUsernameReducer = (state, action) => {
  switch (action.type) {

    case FORGOT_USERNAME_ACTION.SET_EXIST_STATUS: {
      const { field, value } = action.payload;
      return { ...state, [field]: value ?? false };
    }

    case FORGOT_USERNAME_ACTION.SET_SELECTED_VERIFY_TYPE: {
      return { ...state, selectedVerifyType: action.payload };
    }

    case FORGOT_USERNAME_ACTION.SET_VERIFY_ID: {
      const { field, value } = action.payload;
      return { ...state, [field]: value };
    }

    case FORGOT_USERNAME_ACTION.SET_VERIFY_CODE: {
      const { field, value } = action.payload;
      return { ...state, [field]: value };
    }

    case FORGOT_USERNAME_ACTION.SET_CODE_VALIDATION_RESULT: {
      return { ...state, codeValidationResult: action.payload };
    }

    case FORGOT_USERNAME_ACTION.SET_COUNTRY_DAILING_CODE: {
      const countryDailingCode = action.payload
      return { ...state, countryDailingCode, selectedCountryDailingCode: countryDailingCode?.length > 0 ? countryDailingCode[0] : '60' };

    }

    case FORGOT_USERNAME_ACTION.SET_SELECTED_COUNTRY_DAILING_CODE: {
      return { ...state, selectedCountryDailingCode: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

// VALIDATION RULES
const getValidationRules = (type, t, getValues, appState) => {
  switch (type) {
    case 'email':
      return {
        required: appState.registration.emailRequired && t('error_email_empty'),
        pattern: { value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, message: t('error_email_format') },
      };
    case 'phone_number':
      return {
        required: appState.registration.phoneRequired && t('error_phone_number_empty'),
        // pattern: { value: /^\(?(1[0-9]{1})\)?[-. ]?([0-9]{7,8})$/, message: t('error_phone_number_format') },
        pattern: { value: /^\d{8,}$/, message: t('error_phone_number_format') }
      };
    default:
      return {};
  }
};



const Forgotusername = (props) => {
  const appState = useStoreState()
  const { t } = useTranslation();
  const { query, mutation } = useMiddletier()
  const { desktopTitleToggle, mobileTitleToggle } = props;
  const navigate = useNavigate()
  const [forgotUsernameState, forgotUsernameDispatch] = useReducer(forgotUsernameReducer, initialForgotUsernameData)
  const [toLink, setToLink] = useState('/')

  const appDispatch = useStoreDispatch()

  const [verifyDialogShow, setverifyDialogShow] = useState(false);
  const [disabledPhoneBtn, setdisabledPhoneBtn] = useState(false);
  const [disabledEmailBtn, setdisabledEmailBtn] = useState(false);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);

  // const [checkCode, setCheckCode] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingOTP, setLoadingOTP] = useState(false);

  const [timeExpires, setTimeExpires] = useState(+`${appState.registration.otpPinExpiresIn}`);
  const [send, setSend] = useState(false);
  const minutes = Math.floor(timeExpires / 60000);
  const seconds = Math.floor((timeExpires % 60000) / 1000);
  const isProcessing = useRef(false);
  const [visitorId, SetVisitorId] = useState()

  const {
    register: forgot_username,
    getValues,
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const {
    register: verifyCode,
    getValues: getCodeValue,
    formState: { errors: errorsCode },
    // handleSubmit: handleSubmitCode,
  } = useForm({
    mode: "onChange",
  });


  const onChangeCountryDailingCode = (code) => {
    forgotUsernameDispatch({ type: FORGOT_USERNAME_ACTION.SET_SELECTED_COUNTRY_DAILING_CODE, payload: code })
  }

  // WHEN FOCUS VERIFICATION CODE INPUT
  const onFocusVerificationCode = () => {
    forgotUsernameDispatch({ type: FORGOT_USERNAME_ACTION.SET_CODE_VALIDATION_RESULT, payload: true })
  }

  // WHEN FOCUS PHONE NUMBER INPUT
  const onFocusPhoneNumber = () => {
    forgotUsernameDispatch({
      type: FORGOT_USERNAME_ACTION.SET_EXIST_STATUS,
      payload: { field: 'phoneNumberExist', value: true },
    });
    forgotUsernameDispatch({
      type: FORGOT_USERNAME_ACTION.SET_EXIST_STATUS,
      payload: { field: 'phoneNumberError', value: false },
    });
    setdisabledPhoneBtn(false)
  }

  // WHEN FOCUS EMAIL INPUT
  const onFocusEmail = () => {
    forgotUsernameDispatch({
      type: FORGOT_USERNAME_ACTION.SET_EXIST_STATUS,
      payload: { field: 'emailExist', value: false },
    });
    forgotUsernameDispatch({
      type: FORGOT_USERNAME_ACTION.SET_EXIST_STATUS,
      payload: { field: 'emailExistAlert', value: false },
    });
    setdisabledPhoneBtn(false)
  }

  // CHECK IF EMAIL / PHONENUMBER (CONTACT) EXIST
  const checkExist = (type) => {
    const { email, phone_number } = getValues();
    let checkFormatPhoneNumber = phone_number.replace(/^0+/, '');
    // console.log('checkFormatPhoneNumber', checkFormatPhoneNumber)
    const valueMap = {
      EMAIL: email,
      SMS: checkFormatPhoneNumber,
    };
    const method = (type === 'EMAIL' ? 'checkEmail' : 'checkPhoneNumber')
    // const checkCurrency = !currency ? appState.registration.countryDailingCode[0] : currency
    const checkCurrency = forgotUsernameState.selectedCountryDailingCode || appState.registration.defaultCountryDailingCode
    if (valueMap[type]?.length > 0) {
      query({
        method,
        params: [
          { code: type === 'EMAIL' ? 'email' : (type === 'SMS' ? 'phone_number' : 'username'), graphqlType: 'String', required: true, value: type === 'EMAIL' ? email : `${checkCurrency}${checkFormatPhoneNumber}` },
        ],
        attributes: []

      }).then(({ data }) => {
        if (type === 'EMAIL') {
          setdisabledEmailBtn(true);
          forgotUsernameDispatch({
            type: FORGOT_USERNAME_ACTION.SET_EXIST_STATUS,
            payload: { field: 'emailExist', value: false },
          });
          forgotUsernameDispatch({
            type: FORGOT_USERNAME_ACTION.SET_EXIST_STATUS,
            payload: { field: 'emailError', value: false },
          });

        }

        else {
          setdisabledPhoneBtn(true);
          forgotUsernameDispatch({
            type: FORGOT_USERNAME_ACTION.SET_EXIST_STATUS,
            payload: { field: 'phoneNumberExist', value: false },
          });
          forgotUsernameDispatch({
            type: FORGOT_USERNAME_ACTION.SET_EXIST_STATUS,
            payload: { field: 'phoneNumberError', value: false },
          });

        }

      }).catch((error) => {
        console.log('error: ', error)
        if (type === 'EMAIL') {
          setdisabledEmailBtn(false);
          if (error.toString().includes("Invalid")) {
            forgotUsernameDispatch({
              type: FORGOT_USERNAME_ACTION.SET_EXIST_STATUS,
              payload: { field: 'emailError', value: true },
            });
          } else {
            forgotUsernameDispatch({
              type: FORGOT_USERNAME_ACTION.SET_EXIST_STATUS,
              payload: { field: 'emailExist', value: true },
            });
            onClickVerify(type)
          }

        }
        else {
          setdisabledPhoneBtn(false);
          if (error.toString().includes("Invalid")) {
            forgotUsernameDispatch({
              type: FORGOT_USERNAME_ACTION.SET_EXIST_STATUS,
              payload: { field: 'phoneNumberError', value: true },
            });
          } else {
            forgotUsernameDispatch({
              type: FORGOT_USERNAME_ACTION.SET_EXIST_STATUS,
              payload: { field: 'phoneNumberExist', value: true },
            });
            onClickVerify(type)
          }

        }
      })
    }
  }

  const onClickVerify = (verifyType) => {
    if (isProcessing.current) {
      return;
    }
    isProcessing.current = true;
    setdisabledPhoneBtn(true)
    // console.log('forgotUsernameState.phoneNumberExist', forgotUsernameState.phoneNumberExist)
    // console.log('forgotUsernameState.emailExist', forgotUsernameState.emailExist)

    if (forgotUsernameState.phoneNumberExist || forgotUsernameState.emailExist) {
      if (!send) {
        setLoadingOTP(true)
        const { phone_number, email } = getValues();
        // const checkCurrency = !currency ? appState.registration.countryDailingCode[0] : currency
        const checkCurrency = forgotUsernameState.selectedCountryDailingCode || appState.registration?.defaultCountryDailingCode
        const method = 'requestOTP'
        let checkFormatPhoneNumber = phone_number.replace(/^0+/, '');
        const value_destination = verifyType === 'SMS' ? `${checkCurrency}${checkFormatPhoneNumber}` : email
        // console.log('channel', verifyType)
        // console.log('destination', value_destination)
        mutation([{
          method,
          params: [
            { code: 'channel', graphqlType: 'String', required: false, value: verifyType },
            { code: 'destination', graphqlType: 'String', required: true, value: value_destination },
            { code: 'type', graphqlType: 'String', required: true, value: "MEMBER_FORGET_USERNAME" },
            { code: 'fingerprint', graphqlType: 'String', required: false, value: visitorId },
          ],
          attributes: []

        }])
          .then(({ data }) => {
            isProcessing.current = false;
            setdisabledPhoneBtn(false)
            setLoadingOTP(false)
            // verifyType === 'SMS' ? setVerifyID_SMS(data[method].id) : setVerifyID_EMAIL(data[method].id)
            forgotUsernameDispatch({
              type: FORGOT_USERNAME_ACTION.SET_VERIFY_ID,
              payload: { field: `${verifyType === 'SMS' ? 'verifyID_SMS' : 'verifyID_EMAIL'}`, value: data[method].id },
            });

            setSend(true)
            setTimeExpires(+`${appState.registration.otpPinExpiresIn}`)
            forgotUsernameDispatch({ type: FORGOT_USERNAME_ACTION.SET_SELECTED_VERIFY_TYPE, payload: verifyType })
            setverifyDialogShow(true)

          })
          .catch((error) => {
            isProcessing.current = false;
            setdisabledPhoneBtn(false)
            console.log('error: ', error)
            setLoadingOTP(false)
            if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
              console.log('GraphQL Errors:', error.graphQLErrors);
              error.graphQLErrors.forEach((err) => {
                console.log('Error message:', err.message);
                console.log('Error extensions code:', err.extensions?.code);
                if (err.extensions?.code === 'mutationE124') {
                  appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: 'Your device has been blocked for phone verification', typeAlert: 'error' }
                  });
                  setverifyDialogShow(false);
                }
              });
            } 
            
            if (error.toString().includes("suspend")) {
              appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: error.message.toString(), typeAlert: 'error' }
              });
            }
          })
      } else {
        isProcessing.current = false;
        setdisabledPhoneBtn(false)
        setverifyDialogShow(true)
      }
      // setVerifyType(verifyType)

    }
  }

  const onClickVerifyConfirm = () => {

    const { code } = getCodeValue();

    const method = 'verifyOTP'
    const valueId = forgotUsernameState.selectedVerifyType === 'SMS' ? forgotUsernameState.verifyID_SMS : forgotUsernameState.verifyID_EMAIL
    // console.log('method', method)
    // console.log('valueId', code)

    mutation([{
      method,
      params: [
        { code: 'id', graphqlType: 'String', required: true, value: valueId },
        { code: 'pin', graphqlType: 'String', required: true, value: code },
      ],
      attributes: []

    }])
      .then(({ data }) => {
        // setCheckCode(data[method])
        forgotUsernameDispatch({ type: FORGOT_USERNAME_ACTION.SET_CODE_VALIDATION_RESULT, payload: data[method] })
        setverifyDialogShow(false)
        appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: 'Username has been send to your phone number', forwardUrl: '/login' }
        });
        // data[method] && setverifyDialogShow(false)
        // data[method] && (verifyType === 'SMS' ? setVerifyCode_SMS(code) : setVerifyCode_EMAIL(code))

        // IF CODE VALIDATION RESULT IS TRUE
        // if (data[method]) {
        //   setverifyDialogShow(false)
        //   forgotUsernameDispatch({
        //     type: FORGOT_USERNAME_ACTION.SET_VERIFY_CODE,
        //     payload: { field: `${forgotUsernameState.selectedVerifyType === 'SMS' ? 'verifyCode_SMS' : 'verifyCode_EMAIL'}`, value: code },
        //   });

        // }

      })
      .catch((error) => {
        console.log('error: ', error)
      })

  }


  useEffect(() => {

    const timer = setInterval(() => {
      setTimeExpires(timeExpires - 1000);
    }, 1000);

    if (timeExpires === 0) {
      clearInterval(timer);
      setSend(false)
      forgotUsernameDispatch({
        type: FORGOT_USERNAME_ACTION.SET_VERIFY_ID,
        payload: { field: `${forgotUsernameState.selectedVerifyType === 'SMS' ? 'verifyID_SMS' : 'verifyID_EMAIL'}`, value: '' },
      });
    }

    return () => {
      clearInterval(timer);
    };

  }, [timeExpires, send, forgotUsernameState.selectedVerifyType]);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  const onSubmitUsername = () => {
    // setLoading(true)
    // const values = getValues();
    // const countryDailingCode = appState.registration.countryDailingCode[0] ?? '60'
    // const method = 'retrieveUsernameByMember'


    // console.log('phone_number', `${countryDailingCode}${values.phone_number}`)
    // console.log('email', values.email)

    // const paramValue = [
    //   { code: 'phone_number', graphqlType: 'String', required: false, value: `${countryDailingCode}${values.phone_number}` },
    //   { code: 'email', graphqlType: 'String', required: false, value: values.email },
    // ]

    // mutation([{
    //   method,
    //   params: paramValue,
    //   attributes: []
    // }])
    //   .then(({ data }) => {
    //     setLoading(false)
    //     console.log('retrieveUsernameByMember', data[method])
    //     setAlertShow({
    //       show: true, message: 'Username has been send to your phone number', type: 'success', onHide: () => {
    //         window.location.reload();
    //       }
    //     });
    //   })
    //   .catch((error) => {
    //     setLoading(false)
    //     console.log('error: ', error)
    //     setAlertShow({ show: true, message: error.toString(), type: 'error' });
    //   })
  };



  useEffect(() => {
    if (!isValid || ((forgotUsernameState.verifyID_SMS === '' && forgotUsernameState.verifyCode_SMS === '') && (forgotUsernameState.verifyID_EMAIL === '' && forgotUsernameState.verifyCode_EMAIL === '')) || !forgotUsernameState.phoneNumberExist || !forgotUsernameState.emailExist) {
      setDisabledSubmitButton(true)
    } else {
      setDisabledSubmitButton(false)
    }

  }, [forgotUsernameState.verifyID_SMS, forgotUsernameState.verifyID_EMAIL, isValid, forgotUsernameState.phoneNumberExist, forgotUsernameState.emailExist, forgotUsernameState.usernameExist, forgotUsernameState.verifyCode_SMS, forgotUsernameState.verifyCode_EMAIL]);


  useEffect(() => {
    const getDefaultCountryDailingCode = appState.registration?.defaultCountryDailingCode ?? '60'
    if (appState.registration?.countryDailingCode?.includes(getDefaultCountryDailingCode)) {
      appState.registration?.countryDailingCode?.splice(appState.registration?.countryDailingCode.indexOf(getDefaultCountryDailingCode), 1);
    }

    appState.registration?.countryDailingCode?.unshift(getDefaultCountryDailingCode);

    forgotUsernameDispatch({ type: FORGOT_USERNAME_ACTION.SET_COUNTRY_DAILING_CODE, payload: appState.registration?.countryDailingCode })

  }, [appState.registration?.countryDailingCode, appState.registration?.defaultCountryDailingCode]);

  useEffect(() => {
    const getVisitorId = async () => {
      const fp = await FingerprintJS.load();
      const retVal = await fp.get();
      // console.log('retVal: ', retVal)
      SetVisitorId(retVal.visitorId);
    };

    getVisitorId();
  }, [])


  return (
    <>
      {
        appState.loggedIn ?
          <Navigate to={toLink} /> :
          <section id="forgotUser_01">
            <div className="forgotUsername_body">
              <PageTitle desktopToggle={false} mobileToggle={mobileTitleToggle} title={'forgotUsername_title'} id={'forgotUsername_title_m'} />
              <Container className={isDesktopOrLaptop && "forgotUsername_container"}>
                <div className="forgotUsername_container_wrap">
                  <div className="forgotUsername_form">
                    <PageTitle desktopToggle={desktopTitleToggle} mobileToggle={false} title={'forgotUsername_title'} id={'forgotUsername_title_d'} />
                    <div>

                      <Form className="forgotUsername_form_step" onSubmit={handleSubmit(onSubmitUsername)}>
                        {/* <div class="verifyType_Tab">
                            <div className={`verify_type ${verifyType === 'SMS' && 'active'}`} onClick={() => { setVerifyType("SMS") }}>{t('phone_number')}</div>
                            <div className={`verify_type ${verifyType === 'EMAIL' && 'active'}`} onClick={() => { setVerifyType("EMAIL") }}>{t('email')}</div>
                            <div className={`verify_type_line`}></div>
                          </div> */}


                        <Form.Group className="form_field" >
                          <Form.Label >{t(forgotUsernameState.selectedVerifyType === 'SMS' ? 'phone_number' : 'email')}<div className="form_required">*</div></Form.Label>
                          {forgotUsernameState.selectedVerifyType === 'SMS' ?
                            <div className="form_field_input">
                              <div className="w-100 d-flex align-items-center">
                                <Form.Select aria-label="select_currency" className="select_currency w-25 me-1" disabled={forgotUsernameState.verifyCode_SMS} {...forgot_username("currency")} value={forgotUsernameState.selectedCountryDailingCode} onChange={(evt) => { onChangeCountryDailingCode(evt.target.value) }}>
                                  {(appState.registration.countryDailingCode ?? []).map(function (item, index) {
                                    return (
                                      <option value={item} key={index} selected={index === 0 && true}>+{item}</option>
                                    )
                                  })}
                                </Form.Select>

                                <Form.Control
                                  type="text"
                                  name="phone_number"
                                  placeholder={t('phone_number')}
                                  className="input_phone_number w-75"
                                  aria-label="phone_number"
                                  autoComplete="off"
                                  disabled={forgotUsernameState.verifyCode_SMS}
                                  // onBlurCapture={() => checkContact('SMS')}
                                  onFocus={onFocusPhoneNumber}
                                  {...forgot_username("phone_number", getValidationRules('phone_number', t, getValues, appState))}
                                  onKeyDown={(event) => {
                                    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight'];
                                    if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
                                      event.preventDefault();
                                    }

                                  }}
                                  onInput={(event) => {
                                    // event.target.value = event.target.value.replace(/[^0-9]/g, '');

                                    const input = event.target.value.replace(/[^0-9]/g, '');
                                    const checkCurrency = forgotUsernameState.selectedCountryDailingCode || appState.registration.defaultCountryDailingCode
                  
                                    if (checkCurrency === '60') {
                                      if (input.startsWith('011')) {
                                        event.target.value = input.slice(0, 11);
                                      } else if (input.startsWith('11')) {
                                        event.target.value = input.slice(0, 10);
                                      } else if (input.startsWith('0')) {
                                        event.target.value = input.slice(0, 10);
                                      } else {
                                        event.target.value = input.slice(0, 9);
                                      }
                                    } else {
                                      event.target.value = input;
                                    }
                                  }}

                                // {...forgot_username("phone_number", {
                                //   required: appState.registration.phoneRequired && t('forgotUsername_error_phone_number_empty'), pattern: { value: /^\(?([0-9]{2})\)?[-. ]?([0-9]{7,8})$/, message: t('forgotUsername_error_phone_number_format') }
                                // })}
                                />
                                {!forgotUsernameState.verifyCode_SMS && <button type="button" className={`color_button forgotUsername_btnVerify ${(forgotUsernameState.verifyID_SMS !== ''|| isLoadingOTP) && ('btnAfterVerify')}`} disabled={!dirtyFields.phone_number ? true : (errors.phone_number && dirtyFields.phone_number ? true : disabledPhoneBtn)} onClick={() => { checkExist("SMS") }}>{t('verify')}</button>}
                              </div>
                              <p className="forgotUsername_error_msg">{errors.phone_number?.message}</p>
                              {/* {(forgotUsernameState.phoneNumberExistAlert !== '' && !errors.phone_number) && (<p className="forgotUsername_error_msg">{forgotUsernameState.phoneNumberExistAlert}</p>)} */}
                              {/* {
                                (() => {
                                  const { phone_number } = getValues();
                                  if ((!forgotUsernameState.phoneNumberExist) && phone_number.length > 0 && !errors.phone_number) {
                                    return (
                                      <p className="forgotUsername_error_msg">
                                        Your phone number has not been registered.
                                      </p>
                                    );
                                  }
                                  return null;
                                })()
                              } */}
                              {(!forgotUsernameState.phoneNumberExist && !errors.phone_number) && <p className="forgotUsername_error_msg">{t('error_phone_number_notExist')}</p>}
                              {(forgotUsernameState.phoneNumberError && !errors.phone_number) && <p className="forgotUsername_error_msg">{t('error_phone_number_format')}</p>}
                            </div> :
                            <div className="form_field_input">
                              <div className="w-100 d-flex align-items-center">
                                <Form.Control
                                  type="text"
                                  name="email"
                                  placeholder={t('email')}
                                  className="input_email"
                                  aria-label="email"
                                  autoComplete="off"
                                  onFocus={onFocusEmail}
                                  onKeyDown={(event) => {
                                    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', '@', '.', '-', '_', 'Shift'];
                                    if (!/[a-zA-Z0-9.@_-]/.test(event.key) && !allowedKeys.includes(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onInput={(event) => {
                                    event.target.value = event.target.value.replace(/[^a-zA-Z0-9.@_-]/g, '');
                                  }}
                                  // onBlurCapture={() => checkContact('EMAIL')}
                                  {...forgot_username("email", getValidationRules('email', t, getValues, appState))}
                                // {...forgot_username("email", {
                                //   required: appState.registration.emailRequired && t('forgotUsername_error_email_empty'), pattern: { value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, message: t('forgotUsername_error_email_format') },
                                // })}
                                />
                                {!forgotUsernameState.verifyCode_EMAIL && <button type="button" className={`color_button forgotUsername_btnVerify`} disabled={!dirtyFields.email ? true : (errors.email && dirtyFields.email ? true : disabledEmailBtn)} onClick={() => { checkExist("EMAIL") }}>{t('verify')}</button>}
                                {/* <button type="button" className={`color_button forgotUsername_btnVerify`} >{t('verify')}</button> */}
                              </div>
                              <p className="forgotUsername_error_msg">{errors.email?.message}</p>

                              {/* {
                                (() => {
                                  const { email } = getValues();
                                  if (!forgotUsernameState.emailExist && email.length > 0 && !errors.email) {
                                    return (
                                      <p className="forgotUsername_error_msg">
                                        Your email has not been registered.
                                      </p>
                                    );
                                  }
                                  return null;
                                })()
                              } */}
                              {/* {(forgotUsernameState.emailExistAlert !== '' && !errors.email) && (<p className="forgotUsername_error_msg">{forgotUsernameState.emailExistAlert}</p>)} */}
                              {(!forgotUsernameState.emailExist && !errors.email) && <p className="forgotUsername_error_msg">{t('error_email_notExist')}</p>}
                              {(forgotUsernameState.emailError && !errors.email) && <p className="forgotUsername_error_msg">{t('error_email_format')}</p>}
                            </div>}

                        </Form.Group>

                        <Modal
                          show={verifyDialogShow}
                          onHide={() => setverifyDialogShow(false)}
                          backdrop="static"
                          keyboard={false}
                          id="forgotUser_verifyDialog"
                          className="verifyDialog"
                          centered
                        >
                          <Form>
                            <Modal.Header>
                              <Modal.Title>{t(forgotUsernameState.selectedVerifyType === 'SMS' ? "phone_number" : "email")} {t('verification_code')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                              <Form.Group className={`form_field`}>
                                <Form.Label> {t('verification_code')}</Form.Label>
                                <div className="form_field_input">
                                  <div className="w-100 position-relative">
                                    <Form.Control
                                      type="number"
                                      name="code"
                                      onFocus={onFocusVerificationCode}
                                      placeholder={t('verification_code')}
                                      className="input_code"
                                      aria-label="Code"
                                      autoComplete="off"
                                      {...verifyCode(`code`, {
                                        required: t('error_code_empty')
                                      })}
                                    />
                                    <div>
                                      {minutes}:{seconds < 10 ? '0' : ''}{seconds}
                                      <input type={'button'} value={t('resend')} className={`color_button btnSendCode`} onClick={() => onClickVerify(forgotUsernameState.selectedVerifyType)} disabled={send} />
                                    </div>
                                  </div>
                                  <p className="error_msg">{errorsCode.code?.message} </p>
                                  {forgotUsernameState.codeValidationResult === false && <p className="error_msg">{t('error_code_noMatch')} </p>}
                                  {/* {checkCode === false && <p className="error_msg">{t('register_error_code_noMatch')} </p>} */}
                                </div>
                              </Form.Group>

                            </Modal.Body>
                            <Modal.Footer>
                              {/* <div>
                                {t('time_remaining')}: {minutes}:{seconds < 10 ? '0' : ''}{seconds}
                                <input type={'button'} value={t('resend')} className={`color_button`} onClick={() => onClickVerify(verifyType)} disabled={send} />
                              </div> */}
                              <div>
                                <button type={'button'} className={`color_button btnCancel`} onClick={() => setverifyDialogShow(false)} >{t('cancel')} </button>
                                <input type={'button'} value={t('confirm')} className={`color_button`} onClick={onClickVerifyConfirm} /></div>

                            </Modal.Footer>
                          </Form>
                        </Modal>

                        {!isDesktopOrLaptop && <div className="forgotUsername_form_reminder">
                          <div className="forgotUsername_form_reminder_title">
                            {t('reminder')}
                          </div>
                          <div className="forgotUsername_form_reminder_content">
                            {forgotUsernameState.selectedVerifyType === 'SMS' ? t('forgotUsername_reminder_phone_number') : t('forgotUsername_reminder_email')}
                          </div>
                        </div>}
                        {/* <Form.Group className="form_field" >
                          <Form.Label ></Form.Label>
                          <div className="form_field_input input_submit_wrap">
                            <input type='submit' className="font_button color_button forgotUsername_btnSubmit" value={t('submit')} disabled={disabledSubmitButton} />
                          </div>
                        </Form.Group> */}

                      </Form>
                      <hr className="forgotUsername_form_line" />

                      {isDesktopOrLaptop && <div className="forgotUsername_form_reminder">
                        <div className="forgotUsername_form_reminder_title">
                          {t('reminder')}
                        </div>
                        <div className="forgotUsername_form_reminder_content">
                          {forgotUsernameState.selectedVerifyType === 'SMS' ? t('forgotUsername_reminder_phone_number') : t('forgotUsername_reminder_email')}
                        </div>
                      </div>}

                    </div>
                  </div>
                </div>
              </Container>
            </div>
            {isLoading && (<Loading />)}
          </section >

      }</>
  );
};

export default Forgotusername;
