import React, { useEffect, useState, useReducer } from "react";
import { Container, Row, Col, Form, Table, Modal } from "react-bootstrap";
import { FaRegCheckCircle } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Title from '../../../pageTitle/default.js';
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../../../../common/storeContext';
import { GLOBAL_BUCKET } from '../../../../common/constants';
import { useMiddletier } from "../../../../common/middletier";
import Loading from '../../../../components/Loading';
import { useNavigate } from "react-router-dom";

const BANKING_ACTION = {
    SET_BANKS: 'SET_BANKS',
    SET_SELECTED_BANK: 'SET_SELECTED_BANK',
    SET_ACCOUNT_NUMBER: 'SET_ACCOUNT_NUMBER',
    SET_BANK_DETAILS: 'SET_BANK_DETAILS',
    SET_REFRESH: 'SET_REFRESH',
}

const initialBankingData = {
    banks: [],
    selectedBank: { id: '', name: '' },
    accountNumber: '',
    bankingDetails: [],
    refresh: false,
}

const bankingReducer = (state, action) => {
    switch (action.type) {

        case BANKING_ACTION.SET_BANK_DETAILS: {
            return { ...state, bankDetails: action.payload };
        }

        case BANKING_ACTION.SET_BANKS: {
            const banks = [...action.payload]
            return { ...state, banks, selectedBank: banks.length > 0 ? { ...banks[0] } : { id: '', name: '' } };
        }

        case BANKING_ACTION.SET_SELECTED_BANK: {
            const selectedBank = state.banks.find((bank) => bank.code === action.payload)
            return { ...state, selectedBank };
        }

        case BANKING_ACTION.SET_ACCOUNT_NUMBER: {
            return { ...state, accountNumber: action.payload };
        }

        case BANKING_ACTION.SET_REFRESH: {
            return { ...state, refresh: action.payload ?? false };
        }



        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}


const BankingDetails = () => {
    const appDispatch = useStoreDispatch()
    const appState = useStoreState()
    const { t } = useTranslation();
    const [showBankingModal, setShowBankingModal] = useState();
    const [bankingState, bankingDispatch] = useReducer(bankingReducer, initialBankingData)
    const { query, mutation } = useMiddletier();
    const [isLoading, setLoading] = useState(false);
    const [disabledSubmit, setDisabledSubmit] = useState(true)
    const navigate = useNavigate();


    const onChangeBank = (newValue) => {
        bankingDispatch({ type: BANKING_ACTION.SET_SELECTED_BANK, payload: newValue })
    }

    const onChangeAccountNumber = (newValue) => {
        bankingDispatch({ type: BANKING_ACTION.SET_ACCOUNT_NUMBER, payload: newValue })
    }

    const clearInput = () => {
        bankingState.banks?.length > 0 && (bankingDispatch({ type: BANKING_ACTION.SET_SELECTED_BANK, payload: bankingState.banks[0]?.code }))
        bankingDispatch({ type: BANKING_ACTION.SET_ACCOUNT_NUMBER, payload: '' })
    }

    // SUBMIT FORM ADD BANK ACCOUNT
    const handleSubmit = () => {
        setLoading(true)
        const method = 'createMemberBankAccount'

        mutation([{
            method,
            params: [
                { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
                { code: 'code', graphqlType: 'String', required: true, value: bankingState.accountNumber },
                { code: 'name', graphqlType: 'String', required: true, value: appState.user?.name },
                { code: 'bank', graphqlType: 'String', required: true, value: bankingState.selectedBank?.code },
            ],
            attributes: ['code']

        }]).then(({ data }) => {
            setLoading(false)
      
            bankingDispatch({ type: BANKING_ACTION.SET_REFRESH, payload: !bankingState.refresh })
            appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: 'Add bank successful.', typeAlert: 'success' }
            });
            setShowBankingModal(false)

        }).catch((error) => {
            setLoading(false)
            console.log('error: ', error)
            if (error?.networkError?.statusCode === 401) {
                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
                appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: error.message.toString(), typeAlert: 'error' }
                });
            }
        })
        clearInput()

    }

    // DELETE BANK ACCOUNT
    // const deleteBank = (bank) => {
    //     console.log('delete_bank')
    // }

    // GET BANK DETAILS
    useEffect(() => {
        setLoading(true)
        const method = 'member_bank_accounts'
        
        if (typeof appState.user?.username !== 'undefined') {
            
            query({
                method,
                params: [
                    { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { member: appState.user?.username, status: "ACTIVE" } } },
                ],
                attributes: ['code', ['name', 'account_name'], ['_label', 'account_number'], ['bank_label', 'bank_name'], 'bank', 'is_validated']

            }).then(({ data }) => {
                setLoading(false)
                bankingDispatch({ type: BANKING_ACTION.SET_BANK_DETAILS, payload: data[method] })

            }).catch((error) => {
                setLoading(false)
                console.log('error: ', error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }
            })
        }


    }, [appState.user, query, bankingState.refresh, appDispatch, navigate]);

    // GET BANK
    useEffect(() => {
        const method = 'banks'

        query({
            method,
            params: [
                { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { currency: appState.user?.currency ?? appState.currency, type: ['BANK','WALLET'], status: 'ACTIVE' } } },
            ],
            attributes: ['code', ['_label', 'name']]

        }).then(({ data }) => {
          
            bankingDispatch({ type: BANKING_ACTION.SET_BANKS, payload: data[method] })

        }).catch((error) => {
            console.log('error: ', error)
            if (error?.networkError?.statusCode === 401) {
                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
                appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: error.message.toString(), typeAlert: 'error' }
                });
            }
        })

    }, [appState.user, query, appDispatch, navigate, appState?.currency]);

    // CHECK FORM SUBMIT
    useEffect(() => {
        if (bankingState.accountNumber !== '') {
            setDisabledSubmit(false)
        }
        else {
            setDisabledSubmit(true)
        }

    }, [bankingState.accountNumber])



    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })

    return (
        <>
            <div className="bankingDetails_body">
                {isDesktopOrLaptop ? <>
                    <div className="font_h2 color_primary wallet_container_title">{t('banking_details')}</div>
                    <div className="wallet_container_wrap">
                        <Table className='myAccount_bankingDetails_table mt-3'>
                            <thead className="color_secondary myAccount_bankingDetails_tableHeader">
                                <tr>
                                    <th>&nbsp;&nbsp;</th>
                                    <th>{t('bank_name')}</th>
                                    <th>{t('account_name')}</th>
                                    <th>{t('account_number')}</th>
                                    <th>{t('verify')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bankingState.bankDetails?.length ? (
                                    bankingState.bankDetails.map(function (item, index) {
                                        return (
                                            <tr key={index}>
                                                <td><img src={`${GLOBAL_BUCKET}/bank/${appState.user.currency?? appState.currency}/${item.bank}.png`} alt={'bank icon'} /></td>
                                                <td>{item.bank_name}</td>
                                                <td>{item.account_name}</td>
                                                <td>{item.account_number}</td>
                                                <td>{item.is_validated && (<FaRegCheckCircle className="font_h2 verify_banking" />)}</td>
                                                {/* <td><FaTrashAlt className="font_h2" onClick={deleteBank(item)} /></td> */}
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <Row>
                            <Col lg={7} >
                                <Form className="myAccount_bankingDetails_form" >
                                    <Form.Group className="form_input_wrap" >
                                        <div className="input_wrap">
                                            <Form.Label>{t('bank')}
                                                <span className="form_required_input">* {t('required_select')}</span>
                                            </Form.Label>
                                            <Form.Select aria-label="bankingDetails_bank" className="input_bankingDetails_bank" value={bankingState.selectedBank?.code} onChange={(evt) => { onChangeBank(evt.target.value) }}>
                                                {bankingState?.banks.map(function (item, index) {
                                                    return (
                                                        <option value={item.code} key={index}>{item.name}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="form_input_wrap " >
                                        <div className="input_wrap">
                                            <Form.Label>{t('account_number')}
                                                <span className="form_required_input">* {t('required_fill_in')}</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                className="input_accountNumber"
                                                aria-label="accountNo"
                                                value={bankingState.accountNumber ?? ''}
                                                onChange={(evt) => { onChangeAccountNumber(evt.target.value) }}
                                                onKeyDown={(evt) => ["e", "E", "+", "-", " ", "."].includes(evt.key) && evt.preventDefault()}
                                                onInput={(evt) => {
                                                    const value = evt.target.value.replace(/[^0-9]/g, "");
                                                    evt.target.value = value;
                                                }}
                                            />
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="form_input_wrap " >
                                        <div className="input_wrap">
                                            <Form.Label>{t('account_name')}
                                                <span className="form_required_input">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className=" input_accountName"
                                                aria-label="accountName"
                                                value={appState.user?.name}
                                                disabled
                                            />
                                        </div>
                                    </Form.Group>
                                    <input className="font_button color_button wallet_btnSubmit" type="button" value={t('add_bank')} disabled={disabledSubmit} onClick={handleSubmit} />
                                </Form>
                            </Col>
                            <Col lg={5} >
                                <div className="wallet_reminder_container">
                                    <div className="wallet_reminder_title">{t('reminder')}</div>
                                    <div className="wallet_reminder_content">
                                        {t('banking_details_reminder')}
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </> : <>
                    <Title desktopToggle={false} mobileToggle={true} title={"banking_details"} />
                    <div className="myAccount_wrap">
                        <Container>
                            <div className="myAccount_bankingDetails_container">
                                {bankingState.bankDetails?.length > 0 && (
                                    bankingState.bankDetails.map(function (item, index) {
                                        return (
                                            <div className="myAccount_bankingDetails_row" key={index}>
                                                <div className="d-flex align-items-center">
                                                    <div className="bankingDetails_row_img"><img src={`${GLOBAL_BUCKET}/bank/${appState.user?.currency ?? appState.currency}/${item.bank}.png`} alt={'bank icon'} /></div>
                                                    <div className="bankingDetails_row_details">
                                                        <label>{item.bank_name}</label>
                                                        <label>{item.account_name}</label>
                                                        <label>{item.account_number}</label>
                                                    </div>
                                                </div>
                                                <div>{item.is_validated && (<FaRegCheckCircle className="font_h2 verify_banking" />)}</div>
                                                {/* <div><FaTrashAlt className="font_h2" onClick={deleteBank(item)} /></div> */}
                                            </div>
                                        )
                                    })
                                )}
                            </div>
                            <button className="font_button color_button wallet_btnSubmit" onClick={() => setShowBankingModal(true)}>
                                {t('add_bank')}
                            </button>
                        </Container>
                    </div>
                    <Modal show={showBankingModal} onHide={() => setShowBankingModal(false)} backdrop="static" centered id="bankingModal">
                        <Modal.Header closeButton>
                            <Modal.Title>{t('add_bank')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="myAccount_bankingDetails_form" >
                                <Form.Group className="form_input_wrap" >
                                    <div className="input_wrap">
                                        <Form.Label>{t('bank')}
                                            <span className="form_required_input">* {t('required_select')}</span>
                                        </Form.Label>
                                        <Form.Select aria-label="bankingDetails_bank" className="input_bankingDetails_bank" value={bankingState.selectedBank?.code} onChange={(evt) => { onChangeBank(evt.target.value) }}>
                                            {bankingState?.banks.map(function (item, index) {
                                                return (
                                                    <option value={item.code} key={index}>{item.name}</option>
                                                )
                                            })}
                                        </Form.Select>
                                    </div>
                                </Form.Group>
                                <Form.Group className="form_input_wrap " >
                                    <div className="input_wrap">
                                        <Form.Label>{t('account_number')}
                                            <span className="form_required_input">* {t('required_fill_in')}</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            className="input_accountNumber"
                                            aria-label="accountNo"
                                            value={bankingState.accountNumber ?? ''}
                                            onChange={(evt) => { onChangeAccountNumber(evt.target.value) }}
                                            onKeyDown={(evt) => ["e", "E", "+", "-", " ", "."].includes(evt.key) && evt.preventDefault()}
                                            onInput={(evt) => {
                                                const value = evt.target.value.replace(/[^0-9]/g, "");
                                                evt.target.value = value;
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group className="form_input_wrap " >
                                    <div className="input_wrap">
                                        <Form.Label>{t('account_name')}
                                            <span className="form_required_input">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            className=" input_accountName"
                                            aria-label="accountName"
                                            value={appState.user?.name}
                                            disabled
                                        />
                                    </div>
                                </Form.Group>
                                <input className="font_button color_button wallet_btnSubmit" type="button" value={t('add_bank')} disabled={disabledSubmit} onClick={handleSubmit} />
                            </Form>
                            <div className="reminder_container">
                                <div className="reminder_title">{t('reminder')}</div>
                                <div className="reminder_content">
                                    {t(`banking_details_reminder`)}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>}
            </div>
            {isLoading && (<Loading />)}
        </>

    );




};


export default BankingDetails;
