import React, { useState, useEffect } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import PageTitle from '../pageTitle/template_01';
import { useForm } from "react-hook-form";
import { useMiddletier } from "../../common/middletier";
import { Navigate, Link } from 'react-router-dom'

import { useStoreDispatch, useStoreState, APP_STORE_ACTION } from '../../common/storeContext'
import Loading from '../../components/Loading';
import './template_01.css';
import FingerprintJS from '@fingerprintjs/fingerprintjs'


const Login = (props) => {

  const appDispatch = useStoreDispatch()
  const appState = useStoreState()
  const { mutation } = useMiddletier()
  const [visitorId, SetVisitorId] = useState()

  const {
    register,
    getValues,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });
  const { mobileTitleToggle } = props;
  const { t } = useTranslation();
  const [loginMultipleShow, setLoginMultipleShow] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  const onSubmitLogin = () => {
    setLoading(true)
    const values = getValues();
    const method = 'loginByMember'
    // console.log('username: ', values.username)
    // let problematicParam = null;
    // const userAgent = window.navigator.userAgent;
    // const isInAppBrowser = /FBAV|FBAN|Instagram|Twitter|Line/i.test(userAgent);
    // if (isInAppBrowser) {
    //   alert('Running in an in-app browser.');
    //   alert('userAgent'+userAgent)

    // } else {
    //   alert('Running in normal browser.');
    //   alert('userAgent : '+userAgent)
    // }



    let params = [
      { code: 'username', graphqlType: 'String', required: true, value: values.username },
      { code: 'password', graphqlType: 'String', required: true, value: values.password },
      { code: 'fingerprint', graphqlType: 'String', required: false, value: visitorId },
    ]

    if (values.force) {
      params.push({ code: 'force', graphqlType: 'Boolean', required: false, value: values.force })
    }
    // params.forEach(param => {
    //   if (param.value && param.value.length > 255) {
    //     problematicParam = param;
    //     return;
    //   }
    // });

    // if (problematicParam) {
    //   alert('Value too long for parameter: ' + problematicParam.code);
    // } else {
    //   alert('check param value no problem');
    // }
    // alert(`params: ${JSON.stringify(params)}`);

    mutation([{
      method,
      params,
      attributes: []
    }])
      .then(({ data }) => {
        setLoading(false)
        setValue('force', false)
        // alert(`login ok ${JSON.stringify(data)}` );
        appDispatch({ type: APP_STORE_ACTION.LOGIN, payload: { ...data[method] } })
      })
      .catch((error) => {
        setLoading(false)
        console.log('error: ', error)
        // alert('catch error statusCode: ' + error?.networkError?.statusCode);
        // alert('catch error: ' + error);
        if (error.toString().includes("multiple devices")) {
          setLoginMultipleShow(true)
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })
  }

  const onSubmitError = (data) => {
    //TODO: add error message, when verify login fail
    console.log(data);
    if (data) {
      // setAlertShow({ show: true, message: Object.entries(data)[0][1].message.toString(), type: 'error' });
      // setAlertShow({ show: true, message: 'Incorrect Username or Password!', type: 'error' });

    }
  }

  const handleInputBlur = (event) => {
    let value = event.target.value;
    // value = value.replace(/ /g, '');

    if (/[^a-zA-Z0-9]/g.test(value)) {
      appDispatch({
        type: APP_STORE_ACTION.SHOW_ALERT,
        payload: { description: 'Please enter valid characters (only English letters and numbers)!', typeAlert: 'error' }
      });
      // value = value.replace(/[^a-zA-Z0-9]/g, '');
      // setValue("username", "");
      value = '';
    }

    // setValue("username", "");

    event.target.value = value;

  };


  useEffect(() => {
    const getVisitorId = async () => {
      const fp = await FingerprintJS.load();
      const retVal = await fp.get();
      // console.log('retVal: ', retVal)
      SetVisitorId(retVal.visitorId);
    };

    getVisitorId();
  }, [])





  return (
    <>
      {
        appState.loggedIn ?
          <Navigate to='/' /> :
          <section id="loginM_01">
            <div className="login_body">
              <PageTitle desktopToggle={false} mobileToggle={mobileTitleToggle} title={'login'} id={'login_title_m'} />
              <Container className={isDesktopOrLaptop && "login_container"}>
                <div className="login_container_wrap">
                  <div className="login_form">
                    <PageTitle desktopToggle={true} mobileToggle={false} title={'login'} id={'login_title_d'} />
                    <div>

                      <Form className="login_form_step" onSubmit={handleSubmit(onSubmitLogin, onSubmitError)}>

                        <Form.Group className="form_field" >
                          <Form.Label >{t('username')}</Form.Label>
                          <div className="form_field_input">
                            <Form.Control
                              {...register("username", { required: t('error_username_empty') })}
                              type="text"
                              placeholder={t('username')}
                              className="input_username"
                              aria-label="Username"
                              autoComplete="off"
                              // onKeyDown={(event) => {
                              //   if (event.key === ' ') {
                              //     event.preventDefault();
                              //   }
                              // }}
                              // onInput={(event) => {
                              //   if (!event.isComposing) {
                              //     if (event.target.value.includes(' ')) {
                              //       event.target.value = event.target.value.replace(/ /g, '');
                              //     }

                              //     event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
                              //   }
                              // }}
                              onInput={(event) => {
                                if (event.target.value.includes(' ')) {
                                  event.target.value = event.target.value.replace(/ /g, '');
                                }
                                // event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
                              }}

                              onBlur={handleInputBlur}
                            />
                            <p className="input_error_msg">{errors.username?.message}</p>
                          </div>
                        </Form.Group>

                        <Form.Group className="form_field" >
                          <Form.Label>{t('password')}</Form.Label>
                          <div className="form_field_input">
                            <Form.Control
                              {...register("password", { required: t('error_password_empty') })}
                              type="password"
                              placeholder={t('password')}
                              className="input_password"
                              aria-label="Password"
                              autoComplete="off"
                              onKeyDown={(event) => {
                                if (event.key === ' ') {
                                  event.preventDefault();
                                }
                              }}
                              onInput={(event) => {
                                if (event.target.value.includes(' ')) {
                                  event.target.value = event.target.value.replace(/ /g, '');
                                }
                              }}
                            />
                            <p className="input_error_msg">{errors.password?.message}</p>
                          </div>
                        </Form.Group>
                        <Form.Group className="form_field" >
                          <Form.Label ></Form.Label>
                          <div className="form_field_input input_submit_wrap">
                            <input type='submit' className="font_button color_button login_btnLogin" value={t('submit')} disabled={!isValid} />
                          </div>
                        </Form.Group>
                        <hr className="login_form_line" />
                        <div className="d-flex justify-content-between" >
                          {/* <Form.Check
                      type="checkbox"
                      id={`default-checkbox`}
                      label={t('login_remember')}

                    /> */}

                          {appState.registration.enabled_otp ? <label className="forgot_content">
                            {t('forgot')} <Link to="/forgotusername" className="forgot_content_username">{t('username')} </Link> {t('or')} <Link to="/forgotpassword" className="forgot_content_password">{t('password')}</Link>   </label> :
                            <>

                              <div className="login_form_reminder">
                                <div className="login_form_reminder_title">
                                  {t('reminder')}
                                </div>
                                <div className="login_form_reminder_content">
                                  {t('disabled_otp_forgot_content')}
                                </div>
                              </div></>}

                        </div>





                      </Form>




                    </div>
                  </div>
                </div>
              </Container>
            </div>

            {isLoading && (<Loading />)}
            <Modal show={loginMultipleShow} onHide={() => setLoginMultipleShow(false)} centered id="multipleLoginDialog">
              <Modal.Header>
                <Modal.Title>{t('multiple_login_title')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{t('multiple_login_content')}</p>
              </Modal.Body>
              <Modal.Footer>
                <button className="cancel_button" onClick={() => setLoginMultipleShow(false)}>
                  {t('cancel')}
                </button>
                <button className="confirm_button" onClick={() => {
                  setValue('force', true)
                  onSubmitLogin();
                  setLoginMultipleShow(false);
                }}>
                  {t('confirm')}
                </button>
              </Modal.Footer>
            </Modal>
          </section >

      }</>
  );
};

export default Login;
